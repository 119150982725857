





























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { groupBy } from 'lodash'

// components
import MentorRateTaskSpeakingForm from '@/components/views/exercise/mentor/MentorRateTaskSpeakingForm.vue'
import MentorSpeakingQuestionsView from '@/components/views/exercise/mentor/MentorSpeakingQuestionsView.vue'
import TaskEmpty from '@/components/TaskEmpty.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CourseType, EducationAnswerRate, EducationAnswerRateComment,
  EducationLargeTaskResource, EducationRateMasterData,
  ExerciseStatus,
  MasterLargeResource,
} from '@/store/types'
import { EducationRateForm } from '@/store/types/forms'

@Component({
  components: {
    MentorRateTaskSpeakingForm,
    MentorSpeakingQuestionsView,
    TaskEmpty,
  },
})
export default class MentorSpeakingTask extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private groupId!: number

  @Prop({ default: null })
  private master!: MasterLargeResource

  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: null })
  private exerciseCourseType!: CourseType

  private get taskOnReview() {
    return (this.task.status.value === ExerciseStatus.CHECKED || this.task.status.value === ExerciseStatus.ON_CHECK) && !this.task.isDeadlineFailed
  }

  private showMentorCommentEditor = false

  private get masterID () {
    return +this.$route.params.masterID
  }

  private get isStatusWait () {
    return this.task.exerciseStatus?.value === ExerciseStatus.WAIT || this.task.exerciseStatus?.value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    return this.task.exerciseStatus?.value === ExerciseStatus.CHECKED || this.task.exerciseStatus?.value === ExerciseStatus.ON_CHECK
  }

  private get isStatusComplete () {
    return this.task.exerciseStatus?.value === ExerciseStatus.COMPLETE
  }

  private get selfID() {
    if (AuthModule.self)
      return AuthModule.self.id
    return null
  }

  private isLoaded = false

  private form: EducationRateForm = {
    answer: '',
    answersRatePoints: {},
    comment: '',
    draftHash: this.task.draftHash || null,
    mediaIds: [],
    mentorRateComments: {},
    points: '',
    sendKey: this.task.hasKey,
  }

  private subscriptionDraft: any = null

  private mounted() {
    const mentorRateComments: Record<number, EducationAnswerRateComment[]> = {}
    if (this.task.speaking) {
      this.task.speaking.questions.forEach(q => {
        mentorRateComments[q.id] = q.rateComment ?
          [{ answerLine: null, mentorComment: q.rateComment, questionId: q.id, uuid: null }] :
          [{ answerLine: null, mentorComment: null, questionId: q.id, uuid: null }]
      })
    }
    this.form = {
      ...this.form,
      mentorRateComments,
    }

    if (!(this.isStatusComplete || this.readonly)) {
      this.subscriptionDraft = this.$centrifuge.newSubscription(`drafts.task.${this.selfID}.${this.task.uuid}.${this.master.user.id}`)
      this.subscriptionDraft.subscribe()
    }
  }

  private destroyed() {
    if (this.subscriptionDraft) {
      this.subscriptionDraft.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscriptionDraft)
    }
  }

  private handleGetComment(comment: string) {
    this.form.comment = comment
  }

  // Обновление формы проверки
  private handleUpdateRateForm(form: EducationRateMasterData) {
    this.form = {
      ...this.form,
      ...this.transformRateFormFromResponse(form),
    }
  }

  // Трансформация формы от бэка к заполнению
  private transformRateFormFromResponse(form: EducationRateMasterData): EducationRateForm {
    const answersRatePoints: Record<number, EducationAnswerRate> = {}
    const mentorRateComments: Record<number, EducationAnswerRateComment[]> = groupBy(form.mentorRateComments, 'questionId')
    if (form.answersRatePoints) {
      form.answersRatePoints.forEach(point => answersRatePoints[point.questionId] = point)
    }
    return {
      ...form,
      answersRatePoints,
      mentorRateComments: {
        ...this.form.mentorRateComments,
        ...mentorRateComments,
      },
    }
  }

  // Трансформация формы от заполнения к бэку
  private transformRateFormFromRequest(form: EducationRateForm, hash?: string): EducationRateMasterData {
    const answersRatePoints: EducationAnswerRate[] = []
    const mentorRateComments: EducationAnswerRateComment[] = []
    Object.values(form.answersRatePoints).forEach(point => answersRatePoints.push(point))
    Object.values(form.mentorRateComments).forEach(comments => {
      comments.filter(comment => comment.mentorComment && comment.mentorComment !== '<p></p>').forEach(comment => {
        mentorRateComments.push({ ...comment, mentorComment: comment.mentorComment?.replace('<p></p>', '') ?? null })
      })
    })
    return {
      ...form,
      answersRatePoints,
      draftHash: hash || null,
      mentorRateComments,
      points: answersRatePoints.length ? answersRatePoints?.reduce((acc: number, cur: EducationAnswerRate) => acc + Number(cur.points), 0).toString() : form.points,
    }
  }

  // Отправка задания на проверку
  private handleRateTaskSubmit(data: { form: EducationRateForm, hash?: string }) {
    MentorExercisesModule.rateMasterTask({
      masterGroupID: this.groupId,
      masterID: this.masterID,
      params: this.transformRateFormFromRequest(data.form, data.hash),
      taskUUID: this.task.uuid,
    })
      .then(() => {
        this.notifySuccess('Оценка выставлена')
        this.$bus.$emit('updateShowRateForm', false)
        this.$bus.$emit('rate-master-task', false)
      })
      .catch(this.notifyError)
  }
}
