import { render, staticRenderFns } from "./Answer.vue?vue&type=template&id=6247c0a4&scoped=true&lang=pug&"
import script from "./Answer.vue?vue&type=script&lang=ts&"
export * from "./Answer.vue?vue&type=script&lang=ts&"
import style0 from "./Answer.vue?vue&type=style&index=0&id=6247c0a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6247c0a4",
  null
  
)

export default component.exports