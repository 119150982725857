









import { Component, Prop, Vue } from 'vue-property-decorator'

import { EducationLargeTaskResultResource, NameValueResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'
import { downloadOriginalFile } from '@/utils/services/config'

@Component
export default class CreativeTaskFile extends Vue {
  @Prop({ required: true })
  private file!: EducationLargeTaskResultResource

  @Prop({ default: false })
  private isMentor!: boolean

  @Prop({ required: true })
  private subject!: NameValueResource

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get mediaFiles() {
    return this.subject.value !== 14 ? this.file.media : this.file.media.map(media => ({
      ...media,
      url: media.type === 'image' ? media.url : `${downloadOriginalFile}${media.id}`,
    }))
  }
}
